import { Task, executeTasksLimitedly } from "./task";

/**
 * 清洗处理器
 */
interface Handler {
    //判断属性路径是否匹配
    match: (dataPath: string) => boolean;
    //当属性路径匹配的时候执行的替换操作，返回新的属性数据，支持异步执行
    replace: (data: any, dataPath?: string) => Promise<any>;
}

type Callback = (ret?: {
    data: any;
}) => void;

function collectReplaceTask(data: object, dataPath: string, handler: Handler, tasks: Task[], cb: Callback) {
    if (handler.match(dataPath)) {
        //需要处理
        tasks.push(async () => {
            let result = {
                data: await handler.replace(data, dataPath)
            };
            cb(result);
        });
    } else {
        const type = typeof data;
        if (data instanceof Array) {
            dataPath = dataPath + "[*]";
            let len = data.length;
            for (let i = 0; i < len; i++) {
                let child = data[i];
                (function (i) {
                    collectReplaceTask(child, dataPath, handler, tasks, (newChild) => {
                        if (newChild) {
                            data[i] = newChild.data;
                        }
                    });
                })(i);
            }
        } else if (type === "object" && data) {
            const childKeys = Object.keys(data);
            for (const childKey of childKeys) {
                let childPath = dataPath + "." + childKey;
                collectReplaceTask(data[childKey], childPath, handler, tasks, (newChild) => {
                    if (newChild) {
                        data[childKey] = newChild.data;
                    }
                });
            }
        }
        cb();
    }
}


/**
 * 清洗数据
 * @param data 待清洗数据，如果是对象，子孙属性会被改变
 * @param handler 清洗处理器
 * @returns 新数据，如果data是对象，新数据返回的引用一样，只是子孙属性可能会被改变
 */
export async function cleanData(data: any, handler: Handler): Promise<any> {
    let tasks: Task[] = [];
    let result: any = undefined;
    collectReplaceTask(data, "$", handler, tasks, (ret) => {
        result = ret;
    });
    await executeTasksLimitedly(tasks, 5); //限制并发数为5
    if (result) {
        return result.data;
    } else {
        return data;
    }
}
