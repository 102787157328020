import { Result, ApiResp } from "../../common";
import { ReqType } from "../../utils";
import { HttpRequestor, Method } from "../../adapter";
import { BusinessType } from "../models";

interface MetaKey {
    id: number;
    encryptedMetaKey: string;
    businessType: BusinessType;
}

export interface ShareKeyInfo {
    id: number;
    metaKeys: MetaKey[];
}

export interface Form {
    vnoId: number;
    hashedShareKey: string;
}

export async function getMerchantShareKey(httpRequest: HttpRequestor, keyServerBase: string, form: Form): Promise<Result<ApiResp<ShareKeyInfo | undefined>, string>> {
    return httpRequest<{
        vnoId: number;
        shareKey: string;
    }, ApiResp<ShareKeyInfo | undefined>, 'json'>({
        method: Method.Post,
        url: keyServerBase + "/api/web/xxx",
        data: ReqType.Json({
            vnoId: form.vnoId,
            shareKey: form.hashedShareKey,
        }),
        respType: "json"
    });
}
