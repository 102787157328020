
import { Bytes, AesKey256, RsaPubKey2048, RsaPriKey2048, sha256, deriveKeyByPBKDF2 } from "./crypto";
export { genRsaKeyPair } from "./crypto";

/**
 * 生成随机URV
 * @returns Bytes
 */
export function newUserRandomValue(): Bytes {
    return Bytes.newRandom(32);
}

/**
 * 根据密码和URV衍生加密Key和授权Key
 * @param password 密码
 * @param userRandomValue URV
 * @returns 加密Key和授权Key
 */
export async function deriveKey(password: string, userRandomValue: Bytes): Promise<{
    encryptKey: AesKey256;
    authKey: Bytes;
}> {
    const salt = sha256(userRandomValue);
    let encryptKeyBytes = await deriveKeyByPBKDF2(Bytes.fromUtf8(password), salt, 32);
    let encryptKey = AesKey256.tryFromBytes(encryptKeyBytes);
    let authKey = sha256(Bytes.fromUtf8(encryptKeyBytes.toHex()));
    return {
        encryptKey,
        authKey
    };
}

/**
 * 生成随机masterKey
 * @returns 新的masterKey
 */
export function newMasterKey(): AesKey256 {
    return AesKey256.newRandom();
}

/**
 * 用加密key加密masterKey
 * @param masterKey masterKey
 * @param encryptKey 加密key
 * @returns masterKey密文
 */
export function encryptMasterKey(masterKey: AesKey256, encryptKey: AesKey256): Bytes {
    return encryptKey.encrypt(masterKey.getBytes());
}

/**
 * 用加密key解密masterKey
 * @param encryptedMasterKey masterKey密文
 * @param encryptKey 加密key
 * @returns masterKey
 */
export function tryDecryptMasterKey(encryptedMasterKey: Bytes, encryptKey: AesKey256): AesKey256 {
    return AesKey256.tryFromBytes(encryptKey.decrypt(encryptedMasterKey));
}

/**
 * 用masterKey加密rsa私钥
 * @param rsaPriKey rsa私钥
 * @param masterKey masterKey
 * @returns 
 */
export function encryptRsaPriKeyByMasterKey(rsaPriKey: RsaPriKey2048, masterKey: AesKey256): Bytes {
    return masterKey.encrypt(Bytes.fromUtf8(rsaPriKey.getString()));
}

/**
 * 用masterKey解密rsa私钥
 * @param encryptedRsaPriKey rsa私钥密文
 * @param masterKey masterKey
 * @returns 
 */
export function tryDecryptRsaPriKeyByMasterKey(encryptedRsaPriKey: Bytes, masterKey: AesKey256): RsaPriKey2048 {
    let rsaPriKeyBytes = masterKey.decrypt(encryptedRsaPriKey);
    const decoder = new TextDecoder();
    let rsaPriKey = decoder.decode(rsaPriKeyBytes.getRaw());
    return RsaPriKey2048.tryFromString(rsaPriKey);
}

/**
 * 生成随机shareKey
 * @returns 新的shareKey
 */
export function newShareKey(): AesKey256 {
    return AesKey256.newRandom();
}

/**
 * 用masterKey加密shareKey（包含定向分享和公共分享）
 * @param shareKey shareKey
 * @param masterKey masterKey
 * @returns shareKey密文
 */
export function encryptShareKeyByMasterKey(shareKey: AesKey256, masterKey: AesKey256): Bytes {
    return masterKey.encrypt(shareKey.getBytes());
}

/**
 * 用masterKey解密shareKey（包含定向分享和公共分享）
 * @param encryptedShareKey shareKey密文
 * @param masterKey masterKey
 * @returns shareKey
 */
export function tryDecryptShareKeyByMasterKey(encryptedShareKey: Bytes, masterKey: AesKey256): AesKey256 {
    return AesKey256.tryFromBytes(masterKey.decrypt(encryptedShareKey));
}

/**
 * 用对端公钥对定向shareKey进行rsa加密
 * @param shareKey 定向shareKey
 * @param publicKey 接收方公钥
 * @returns shareKey密文
 */
export function encryptShareKeyByRsaPubKey(shareKey: AesKey256, publicKey: RsaPubKey2048): Bytes | null {
    return publicKey.encrypt(shareKey.getBytes());
}

/**
 * 用私钥对定向shareKey进行rsa解密
 * @param encryptedShareKey 定向shareKey密文
 * @param privateKey rsa私钥
 * @returns 定向shareKey
 */
export function tryDecryptShareKeyByRsaPriKey(encryptedShareKey: Bytes, privateKey: RsaPriKey2048): AesKey256 | null {
    let shareKey = privateKey.decrypt(encryptedShareKey);
    return shareKey ? AesKey256.tryFromBytes(shareKey) : null;
}

/**
 * 生成随机metaKey
 * @returns 新的metaKey
 */
export function newMetaKey(): AesKey256 {
    return AesKey256.newRandom();
}

/**
 * 用masterKey加密metaKey
 * @param metaKey metaKey
 * @param masterKey masterKey
 * @returns metaKey密文
 */
export function encryptMetaKeyByMasterKey(metaKey: AesKey256, masterKey: AesKey256): Bytes {
    return masterKey.encrypt(metaKey.getBytes());
}

/**
 * 用masterKey解密metaKey
 * @param encryptedMetaKey metaKey密文
 * @param masterKey masterKey
 * @returns metaKey
 */
export function tryDecryptMetaKeyByMasterKey(encryptedMetaKey: Bytes, masterKey: AesKey256): AesKey256 {
    return AesKey256.tryFromBytes(masterKey.decrypt(encryptedMetaKey));
}

/**
 * 用shareKey（包含定向分享和公共分享）加密metaKey
 * @param metaKey metaKey
 * @param shareKey shareKey
 * @returns metaKey密文
 */
export function encryptMetaKeyByShareKey(metaKey: AesKey256, shareKey: AesKey256): Bytes {
    return shareKey.encrypt(metaKey.getBytes());
}

/**
 * 用shareKey（包含定向分享和公共分享）解密metaKey
 * @param encryptedMetaKey metaKey密文
 * @param shareKey shareKey
 * @returns metaKey
 */
export function tryDecryptMetaKeyByShareKey(encryptedMetaKey: Bytes, shareKey: AesKey256): AesKey256 {
    return AesKey256.tryFromBytes(shareKey.decrypt(encryptedMetaKey));
}

/**
 * 根据公共shareKey获取shareKey后缀
 * @param pubShareKey 公共shareKey
 * @returns 公共shareKey后缀
 */
export function getSuffixOfShareKey(pubShareKey: AesKey256): Bytes {
    return new Bytes(pubShareKey.getBytes().getRaw().slice(6));
}

// interface Enc {
//     data: number[];
//     cipher: number[];
// }

// interface Sign {
//     data: number[];
//     signature: number[];
// }

// export function test() {
//     let encList: Enc[] = [];
//     let signList: Sign[] = [];
//     // let keyPair = genRsaKeyPair();
//     let pubKey = RsaPubKey2048.tryFromString(`-----BEGIN PUBLIC KEY-----
// MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEA3giaOAlxJWRMe0fWcIpu
// GZnYiqBwdMEEVytd7tLj3/m1ceBIb0fmcMkO6U6le3WAj/XYxV1ZPHQMh9vzzGkV
// xnNDEZgAOhKTVqvey/zhgXWjVQEYt+sCCASldtm9WNQFZduLbxv+BBZpy1PeUBGS
// qJD4M3IKpbGWMIKDfu6P7XbMTMQ/Z5oPIYeeTrUc6BK49Eqwwl+LZJisfdu2p4Xr
// ldh0EMYLM4mzMfXDd5EYEjC6i5utwoaaMoLys8XxL66wvfZ/H68GqvyUvy8k2haS
// RUZ1SKYUIDCiy4QznJdZk1CHQ/Hmimla57Ur/+vBTZZh+FYGA5iB0us6Uy8slMZ4
// sQIDAQAB
// -----END PUBLIC KEY-----`);
//     let priKey = RsaPriKey2048.tryFromString(`-----BEGIN RSA PRIVATE KEY-----
// MIIEpQIBAAKCAQEA3giaOAlxJWRMe0fWcIpuGZnYiqBwdMEEVytd7tLj3/m1ceBI
// b0fmcMkO6U6le3WAj/XYxV1ZPHQMh9vzzGkVxnNDEZgAOhKTVqvey/zhgXWjVQEY
// t+sCCASldtm9WNQFZduLbxv+BBZpy1PeUBGSqJD4M3IKpbGWMIKDfu6P7XbMTMQ/
// Z5oPIYeeTrUc6BK49Eqwwl+LZJisfdu2p4Xrldh0EMYLM4mzMfXDd5EYEjC6i5ut
// woaaMoLys8XxL66wvfZ/H68GqvyUvy8k2haSRUZ1SKYUIDCiy4QznJdZk1CHQ/Hm
// imla57Ur/+vBTZZh+FYGA5iB0us6Uy8slMZ4sQIDAQABAoIBAQDdGFvZaS2OOm4e
// ZM6YioBQZe5HPLQ+zuVvl/OVmdLoeVPA7cGzbjl+HfgHQxVt9vfqYrlU7zupAzZn
// IxOGfS39y1EB+AZhzZWCPOtC3bIApA/c2Zavrgj7ywSpcPEnvxkQ8KnMHme30knO
// +tjpilYsHQ/KysjnbgtMiuo+P3h60HKLu98O71AVqKMpF1YTg0wU8PJp9+4Jkf/V
// AMF5o8TNubQfIOriD52irkP09hPnpo3dSldd0EBBPdSnw8b+SxJ8Lb0hZkFEfs9v
// EuTJp50KnO4GJ3qUkquUcEf20ZETwCKvVGe8TpXTZg4ot6t8Zl7Q8y6H2KveocBh
// 0+TjuhgBAoGBAPGokfbOG21AQq45dB5fqFF0biH7wL7R2ZJpluDuSk/VbHHzcrwn
// 6jX5FxfDlptHhYjmIZ9bAaN7Xulh7KBRlgo7OQMmcMYhSuvSnt7/Pd5hOvqO8udE
// 9gmdxp4o/qmXVd0UpjpYJrXxDQpDE1jLR3bflyIs1yl+knZ8X7oqwxiRAoGBAOs1
// 4O4ZHdqFXSFYoyUvPOLUluBLl9EKD7OSwJyxWd3uRvBNSfr6Af+7R13w6iZPtCnN
// LKZLHrpspaMmGbeKuLJILiAbD1NHJdY/i2i/60U2fmX8QW1825qcLMjwAUjbeml6
// ETIV5b6sZC7sSbp6nMPPEXVgosutZyhXlgHE8m4hAoGBAMHoPG9oxLMubBL2wQka
// QqpRnYmhQ/EZ29ZS6aFFM9XJcEIjUX5PHO0AGZqU88VXZ3wJBROkZ7UxamAklkWy
// EY3WJZI0hytETaTalWZkMtW8+SFSEPCn8jCXLGd+h5G3gEtspVGNqEo3yPniBqUq
// QAbPtKrUhjWifY2bkQiGBZQxAoGAWHjEqWqDIy3k0OUxQIxbH4sNUUK90SAytb3u
// e0HuZp74yeyYdjHRzKpZ9sjmmhCC2PJW6Bn7be3uA8cSB0rDnDOwPd1Pz2hEoCj4
// vCbsTPVpZ90RkyhSxf4WjmJQaWI80i3bB/bamvTnHgs81ErIapKZo4f81BXeZntD
// bljxQ2ECgYEAse+yfio2a/ospDTw1Gz52eJCSM7Zq/6kcOdBiD4qbM1lTjBNy4Xr
// k8RgmGciMABpTRa/2NAoJQawTwSwYRIbRaZ8eOEjBhWnKBMLsZhr41RZyaYozuxY
// C7VKfKbXzkOXLTgofsXWvcYFRofb4Er4oY56FOPapcadIuvTkwBi/Hk=
// -----END RSA PRIVATE KEY-----`);



//     for (var i = 0; i < 10000; i++) {
//         // let plain = "你好";
//         // let data = Bytes.fromUtf8(plain);
//         // let cipher = keyPair["public"].enc.encrypt(plain);
//         // if (false === cipher) {
//         //     throw "Encrypt by rsa failed.";
//         // }
//         // let cipher = keyPair["public"].encrypt(data);
//         // console.log(cipher.toBase64());
//         // convert a PEM-formatted public key to a Forge public key
//         let count = Math.round((Math.random() * 100)) + 1;
//         let data = Bytes.newRandom(count);
//         let cipher = pubKey.encrypt(data);
//         encList.push({
//             data: Array.from(data.getRaw()),
//             cipher: Array.from(cipher.getRaw()),
//         });
//         let plain = priKey.decrypt(cipher);
//         let ret = data.toBase64() == plain.toBase64();
//         console.log(ret);
//         if (!ret) {
//             console.log(pubKey.getString());
//             console.log(priKey.getString());
//             console.log(JSON.stringify(Array.from(data.getRaw())));
//             break;
//         }
//         let signature = priKey.sign(data);
//         signList.push({
//             data: Array.from(data.getRaw()),
//             signature: Array.from(signature.getRaw()),
//         });
//         ret = pubKey.verify(data, signature);
//         console.log(ret);
//         if (!ret) {
//             console.log(pubKey.getString());
//             console.log(priKey.getString());
//             console.log(JSON.stringify(Array.from(signature.getRaw())));
//             break;
//         }
//     }
//     console.log(JSON.stringify({
//         enc: encList,
//         sign: signList
//     }));

//     // let newdata = pubKey.encrypt(new Bytes(new Uint8Array()));
//     // console.log(JSON.stringify(Array.from(newdata.getRaw())));


//     // let ret = newdata.toBase64() == data.toBase64();
//     // let decoder = new TextDecoder();
//     // console.log(decoder.decode(newdata.getRaw()));


//     // for (let i = 0; i < 100; i++) {
//     //     let data = Bytes.newRandom(100);
//     //     let cipher = keyPair["public"].encrypt(data);
//     //     let newdata = keyPair["private"].decrypt(cipher);
//     //     let ret = newdata.toBase64() == data.toBase64();
//     //     console.log(ret);
//     //     if (!ret) {
//     //         break;
//     //     }
//     // }
// }
