import { Result, ApiResp } from "../../common";
import { ReqType } from "../../utils";
import { BusinessType } from "../models";
import { HttpRequestor, Method } from "../../adapter";

interface MetaKey {
    metaKeyId: number;
    encMetaKey: string;
    businessType: BusinessType;
}

export interface ShareKeyInfo {
    shareKeyId: number;
    timestamp: number;
    encMetaKeys: MetaKey[];
}

export interface Form {
    uuid: string;
    lang: string;
    appVersionCode: number;
    appId: number;
    brandId: number;
    vnoId: number;
    deviceInfo: object;
    originType: number;
    requestTimeTs: number;
    mac: string;
    token: string;
    accountName: string;
    hashedShareKey: string;
    clientType: string;
}

export async function getShareKey(httpRequest: HttpRequestor, keyServerBase: string, form: Form): Promise<Result<ApiResp<ShareKeyInfo | undefined>, string>> {
    return httpRequest<Form, ApiResp<ShareKeyInfo | undefined>, 'json'>({
        method: Method.Post,
        url: keyServerBase + "/liveapi/api/v5/account/key/info/query",
        data: ReqType.Json(form),
        respType: "json"
    });
}
