/* eslint-disable @typescript-eslint/no-use-before-define */
type Handler<T> = (data: NonNullable<T>) => void;
type AsyncHandler<T> = (data: NonNullable<T>) => Promise<void>;
type Mapper<I, O> = (input: NonNullable<I>) => O;
type AsyncMapper<I, O> = (input: NonNullable<I>) => Promise<O>;

export class Option<T> {
    private _data: T | null | undefined;
    private constructor(data: T | null | undefined) {
        this._data = data;
    }
    static Some<T>(data: T): Option<T> {
        return new Option(data);
    }
    static None<T>(): Option<T> {
        return new Option(undefined as T | null | undefined);
    }
    isSome(): boolean {
        return undefined !== this._data && null !== this._data;
    }
    isNone(): boolean {
        return undefined === this._data || null === this._data;
    }
    unwrapOr(alternate: NonNullable<T>): NonNullable<T> {
        if (this.isSome()) {
            return this._data!;
        } else {
            return alternate;
        }
    }
    unwrapOrElse(fn: () => NonNullable<T>): NonNullable<T> {
        if (this.isSome()) {
            return this._data!;
        } else {
            return fn();
        }
    }
    ignoreNone(someFn: Handler<T>) {
        if (this.isSome()) {
            someFn(this._data!);
        }
    }
    match(someFn: Handler<T>, noneFn?: () => void) {
        if (this.isSome()) {
            someFn(this._data!);
        } else {
            noneFn && noneFn();
        }
    }
    async matchAsync(someFn: AsyncHandler<T>, noneFn?: () => Promise<void>) {
        if (this.isSome()) {
            await Promise.resolve(someFn(this._data!));
        } else {
            if (noneFn) {
                await Promise.resolve(noneFn());
            }
        }
    }
    map<O>(mapFn: Mapper<T, O>): Option<O> {
        if (this.isSome()) {
            return Option.Some(mapFn(this._data!));
        } else {
            return Option.None();
        }
    }
    async mapAsync<O>(mapFn: AsyncMapper<T, O>): Promise<Option<O>> {
        if (this.isSome()) {
            return Option.Some(await Promise.resolve(mapFn(this._data!)));
        } else {
            return Option.None();
        }
    }
    replace(data: T): Option<T> {
        const oldData = this._data;
        this._data = data;
        if (undefined === oldData || null === oldData) {
            return Option.None();
        } else {
            return Option.Some(oldData);
        }
    }
    take(): Option<T> {
        const data = this._data;
        this._data = undefined;
        if (undefined === data || null === data) {
            return Option.None();
        } else {
            return Option.Some(data);
        }
    }
    okOr<E>(err: E): Result<T, E> {
        if (this.isSome()) {
            return Result.Ok(this._data!);
        } else {
            return Result.Err(err);
        }
    }
    okOrElse<E>(fn: () => NonNullable<E>): Result<T, E> {
        if (this.isSome()) {
            return Result.Ok(this._data!);
        } else {
            return Result.Err(fn());
        }
    }
    async okOrElseAsync<E>(fn: () => Promise<NonNullable<E>>): Promise<Result<T, E>> {
        if (this.isSome()) {
            return Result.Ok(this._data!);
        } else {
            return Result.Err(await Promise.resolve(fn()));
        }
    }
}

export class Result<T, E> {
    private _ok: boolean;
    private _data: T | null | undefined;
    private _err: E | null | undefined;
    private constructor(data: T | null | undefined, err: E | null | undefined, ok: boolean) {
        this._data = data;
        this._err = err;
        this._ok = ok;
    }
    static Ok<T, E>(data: T): Result<T, E> {
        return new Result(data, undefined as E | null | undefined, true);
    }
    static Err<T, E>(err: E): Result<T, E> {
        return new Result(undefined as T | null | undefined, err, false);
    }
    isOk(): boolean {
        return this._ok;
    }
    isErr(): boolean {
        return !this._ok;
    }
    unwrapOr(alternate: NonNullable<T>): NonNullable<T> {
        if (this.isOk()) {
            return this._data!;
        } else {
            return alternate;
        }
    }
    unwrapOrElse(fn: () => NonNullable<T>): NonNullable<T> {
        if (this.isOk()) {
            return this._data!;
        } else {
            return fn();
        }
    }
    ignoreError(okFn: Handler<T>) {
        if (this.isOk()) {
            okFn(this._data!);
        }
    }
    match(okFn?: Handler<T>, errFn?: Handler<E>) {
        if (this.isOk()) {
            okFn && okFn(this._data!);
        } else {
            errFn && errFn(this._err!);
        }
    }
    async matchAsync(okFn?: AsyncHandler<T>, errFn?: AsyncHandler<E>) {
        if (this.isOk()) {
            if (okFn) {
                await Promise.resolve(okFn(this._data!));
            }
        } else {
            if (errFn) {
                await Promise.resolve(errFn(this._err!));
            }
        }
    }
    map<O>(mapFn: Mapper<T, O>): Result<O, E> {
        if (this.isOk()) {
            return Result.Ok(mapFn(this._data!));
        } else {
            return Result.Err(this._err!);
        }
    }
    mapErr<O>(mapFn: Mapper<E, O>): Result<T, O> {
        if (this.isOk()) {
            return Result.Ok(this._data!);
        } else {
            return Result.Err(mapFn(this._err!));
        }
    }
    async mapAsync<O>(mapFn: AsyncMapper<T, O>): Promise<Result<O, E>> {
        if (this.isOk()) {
            return Result.Ok(await Promise.resolve(mapFn(this._data!)));
        } else {
            return Result.Err(this._err!);
        }
    }
    async mapErrAsync<O>(mapFn: AsyncMapper<E, O>): Promise<Result<T, O>> {
        if (this.isOk()) {
            return Result.Ok(this._data!);
        } else {
            return Result.Err(await Promise.resolve(mapFn(this._err!)));
        }
    }
    ok(): Option<T> {
        if (this.isOk()) {
            return Option.Some(this._data!);
        } else {
            return Option.None();
        }
    }
    toPromise(): Promise<T> {
        return new Promise((resolve, reject) => {
            this.match(resolve, reject);
        });
    }
}

enum Enum2Type {
    First,
    Second
}

export class Enum2<T1, T2> {
    private _dataType: Enum2Type;
    private _data: T1 | T2;
    private constructor(dataType: Enum2Type, data: T1 | T2) {
        this._dataType = dataType;
        this._data = data;
    }
    static First<T1, T2>(data: T1): Enum2<T1, T2> {
        return new Enum2<T1, T2>(Enum2Type.First, data);
    }
    static Second<T1, T2>(data: T2): Enum2<T1, T2> {
        return new Enum2<T1, T2>(Enum2Type.Second, data);
    }
    isFirst(): boolean {
        return Enum2Type.First === this._dataType;
    }
    isSecond(): boolean {
        return Enum2Type.Second === this._dataType;
    }
    unwrapFirstOr(alternate: NonNullable<T1>): NonNullable<T1> {
        if (this.isFirst()) {
            return this._data as any;
        } else {
            return alternate;
        }
    }
    unwrapFirstOrElse(fn: () => NonNullable<T1>): NonNullable<T1> {
        if (this.isFirst()) {
            return this._data as any;
        } else {
            return fn();
        }
    }
    unwrapSecondOr(alternate: NonNullable<T2>): NonNullable<T2> {
        if (this.isSecond()) {
            return this._data as any;
        } else {
            return alternate;
        }
    }
    unwrapSecondOrElse(fn: () => NonNullable<T2>): NonNullable<T2> {
        if (this.isSecond()) {
            return this._data as any;
        } else {
            return fn();
        }
    }
    match(firstFn?: Handler<T1>, secondFn?: Handler<T2>) {
        if (this.isFirst() && firstFn) {
            firstFn(this._data as any);
        } else if (this.isSecond() && secondFn) {
            secondFn(this._data as any);
        }
    }
    matchFirst(fn: Handler<T1>) {
        if (this.isFirst()) {
            fn(this._data as any);
        }
    }
    matchSecond(fn: Handler<T2>) {
        if (this.isSecond()) {
            fn(this._data as any);
        }
    }
    async matchAsync(firstFn?: AsyncHandler<T1>, secondFn?: AsyncHandler<T2>) {
        if (this.isFirst() && firstFn) {
            await Promise.resolve(firstFn(this._data as any));
        } else if (this.isSecond() && secondFn) {
            await Promise.resolve(secondFn(this._data as any));
        }
    }
    async matchFirstAsync(fn: AsyncHandler<T1>) {
        if (this.isFirst()) {
            await Promise.resolve(fn(this._data as any));
        }
    }
    async matchSecondAsync(fn: AsyncHandler<T2>) {
        if (this.isSecond()) {
            await Promise.resolve(fn(this._data as any));
        }
    }
    mapFirst<O>(mapFn: Mapper<T1, O>): Enum2<O, T2> {
        if (this.isFirst()) {
            return Enum2.First(mapFn(this._data as any));
        } else {
            return Enum2.Second(this._data as any);
        }
    }
    mapSecond<O>(mapFn: Mapper<T2, O>): Enum2<T1, O> {
        if (this.isSecond()) {
            return Enum2.Second(mapFn(this._data as any));
        } else {
            return Enum2.First(this._data as any);
        }
    }
    mapAll<O>(firstFn: Mapper<T1, O>, secondFn: Mapper<T2, O>): O {
        if (this.isFirst()) {
            return firstFn(this._data as any);
        } else {
            return secondFn(this._data as any);
        }
    }
    async mapFirstAsync<O>(mapFn: AsyncMapper<T1, O>): Promise<Enum2<O, T2>> {
        if (this.isFirst()) {
            return Enum2.First(await Promise.resolve(mapFn(this._data as any)));
        } else {
            return Enum2.Second(this._data as any);
        }
    }
    async mapSecondAsync<O>(mapFn: AsyncMapper<T2, O>): Promise<Enum2<T1, O>> {
        if (this.isSecond()) {
            return Enum2.Second(await Promise.resolve(mapFn(this._data as any)));
        } else {
            return Enum2.First(this._data as any);
        }
    }
    async mapAllAsync<O>(firstFn: AsyncMapper<T1, O>, secondFn: AsyncMapper<T2, O>): Promise<O> {
        if (this.isFirst()) {
            return firstFn(this._data as any);
        } else {
            return secondFn(this._data as any);
        }
    }
}

enum Enum3Type {
    First,
    Second,
    Third
}

export class Enum3<T1, T2, T3> {
    private _dataType: Enum3Type;
    private _data: T1 | T2 | T3;
    private constructor(dataType: Enum3Type, data: T1 | T2 | T3) {
        this._dataType = dataType;
        this._data = data;
    }
    static First<T1, T2, T3>(data: T1): Enum3<T1, T2, T3> {
        return new Enum3<T1, T2, T3>(Enum3Type.First, data);
    }
    static Second<T1, T2, T3>(data: T2): Enum3<T1, T2, T3> {
        return new Enum3<T1, T2, T3>(Enum3Type.Second, data);
    }
    static Third<T1, T2, T3>(data: T3): Enum3<T1, T2, T3> {
        return new Enum3<T1, T2, T3>(Enum3Type.Third, data);
    }
    isFirst(): boolean {
        return Enum3Type.First === this._dataType;
    }
    isSecond(): boolean {
        return Enum3Type.Second === this._dataType;
    }
    isThird(): boolean {
        return Enum3Type.Third === this._dataType;
    }
    unwrapFirstOr(alternate: NonNullable<T1>): NonNullable<T1> {
        if (this.isFirst()) {
            return this._data as any;
        } else {
            return alternate;
        }
    }
    unwrapFirstOrElse(fn: () => NonNullable<T1>): NonNullable<T1> {
        if (this.isFirst()) {
            return this._data as any;
        } else {
            return fn();
        }
    }
    unwrapSecondOr(alternate: NonNullable<T2>): NonNullable<T2> {
        if (this.isSecond()) {
            return this._data as any;
        } else {
            return alternate;
        }
    }
    unwrapSecondOrElse(fn: () => NonNullable<T2>): NonNullable<T2> {
        if (this.isSecond()) {
            return this._data as any;
        } else {
            return fn();
        }
    }
    unwrapThirdOr(alternate: NonNullable<T3>): NonNullable<T3> {
        if (this.isThird()) {
            return this._data as any;
        } else {
            return alternate;
        }
    }
    unwrapThirdOrElse(fn: () => NonNullable<T3>): NonNullable<T3> {
        if (this.isThird()) {
            return this._data as any;
        } else {
            return fn();
        }
    }
    match(firstFn?: Handler<T1>, secondFn?: Handler<T2>, thirdFn?: Handler<T3>) {
        if (this.isFirst() && firstFn) {
            firstFn(this._data as any);
        } else if (this.isSecond() && secondFn) {
            secondFn(this._data as any);
        } else if (this.isThird() && thirdFn) {
            thirdFn(this._data as any);
        }
    }
    matchFirst(fn: Handler<T1>) {
        if (this.isFirst()) {
            fn(this._data as any);
        }
    }
    matchSecond(fn: Handler<T2>) {
        if (this.isSecond()) {
            fn(this._data as any);
        }
    }
    matchThird(fn: Handler<T3>) {
        if (this.isThird()) {
            fn(this._data as any);
        }
    }
    async matchAsync(firstFn?: AsyncHandler<T1>, secondFn?: AsyncHandler<T2>, thirdFn?: AsyncHandler<T3>) {
        if (this.isFirst() && firstFn) {
            await Promise.resolve(firstFn(this._data as any));
        } else if (this.isSecond() && secondFn) {
            await Promise.resolve(secondFn(this._data as any));
        } else if (this.isThird() && thirdFn) {
            await Promise.resolve(thirdFn(this._data as any));
        }
    }
    async matchFirstAsync(fn: AsyncHandler<T1>) {
        if (this.isFirst()) {
            await Promise.resolve(fn(this._data as any));
        }
    }
    async matchSecondAsync(fn: AsyncHandler<T2>) {
        if (this.isSecond()) {
            await Promise.resolve(fn(this._data as any));
        }
    }
    async matchThirdAsync(fn: AsyncHandler<T3>) {
        if (this.isThird()) {
            await Promise.resolve(fn(this._data as any));
        }
    }
    mapFirst<O>(mapFn: Mapper<T1, O>): Enum3<O, T2, T3> {
        if (this.isFirst()) {
            return Enum3.First(mapFn(this._data as any));
        } else if (this.isSecond()) {
            return Enum3.Second(this._data as any);
        } else {
            return Enum3.Third(this._data as any);
        }
    }
    mapSecond<O>(mapFn: Mapper<T2, O>): Enum3<T1, O, T3> {
        if (this.isSecond()) {
            return Enum3.Second(mapFn(this._data as any));
        } else if (this.isFirst()) {
            return Enum3.First(this._data as any);
        } else {
            return Enum3.Third(this._data as any);
        }
    }
    mapThird<O>(mapFn: Mapper<T3, O>): Enum3<T1, T2, O> {
        if (this.isThird()) {
            return Enum3.Third(mapFn(this._data as any));
        } else if (this.isFirst()) {
            return Enum3.First(this._data as any);
        } else {
            return Enum3.Second(this._data as any);
        }
    }
    mapAll<O>(firstFn: Mapper<T1, O>, secondFn: Mapper<T2, O>, thirdFn: Mapper<T3, O>): O {
        if (this.isFirst()) {
            return firstFn(this._data as any);
        } else if (this.isSecond()) {
            return secondFn(this._data as any);
        } else {
            return thirdFn(this._data as any);
        }
    }
    async mapFirstAsync<O>(mapFn: AsyncMapper<T1, O>): Promise<Enum3<O, T2, T3>> {
        if (this.isFirst()) {
            return Enum3.First(await Promise.resolve(mapFn(this._data as any)));
        } else if (this.isSecond()) {
            return Enum3.Second(this._data as any);
        } else {
            return Enum3.Third(this._data as any);
        }
    }
    async mapSecondAsync<O>(mapFn: AsyncMapper<T2, O>): Promise<Enum3<T1, O, T3>> {
        if (this.isSecond()) {
            return Enum3.Second(await Promise.resolve(mapFn(this._data as any)));
        } else if (this.isFirst()) {
            return Enum3.First(this._data as any);
        } else {
            return Enum3.Third(this._data as any);
        }
    }
    async mapThirdAsync<O>(mapFn: AsyncMapper<T3, O>): Promise<Enum3<T1, T2, O>> {
        if (this.isThird()) {
            return Enum3.Third(await Promise.resolve(mapFn(this._data as any)));
        } else if (this.isFirst()) {
            return Enum3.First(this._data as any);
        } else {
            return Enum3.Second(this._data as any);
        }
    }
    async mapAllAsync<O>(firstFn: AsyncMapper<T1, O>, secondFn: AsyncMapper<T2, O>, thirdFn: AsyncMapper<T3, O>): Promise<O> {
        if (this.isFirst()) {
            return firstFn(this._data as any);
        } else if (this.isSecond()) {
            return secondFn(this._data as any);
        } else {
            return thirdFn(this._data as any);
        }
    }
}

enum Enum4Type {
    First,
    Second,
    Third,
    Fourth
}

export class Enum4<T1, T2, T3, T4> {
    private _dataType: Enum4Type;
    private _data: T1 | T2 | T3 | T4;
    private constructor(dataType: Enum4Type, data: T1 | T2 | T3 | T4) {
        this._dataType = dataType;
        this._data = data;
    }
    static First<T1, T2, T3, T4>(data: T1): Enum4<T1, T2, T3, T4> {
        return new Enum4<T1, T2, T3, T4>(Enum4Type.First, data);
    }
    static Second<T1, T2, T3, T4>(data: T2): Enum4<T1, T2, T3, T4> {
        return new Enum4<T1, T2, T3, T4>(Enum4Type.Second, data);
    }
    static Third<T1, T2, T3, T4>(data: T3): Enum4<T1, T2, T3, T4> {
        return new Enum4<T1, T2, T3, T4>(Enum4Type.Third, data);
    }
    static Fourth<T1, T2, T3, T4>(data: T4): Enum4<T1, T2, T3, T4> {
        return new Enum4<T1, T2, T3, T4>(Enum4Type.Fourth, data);
    }
    isFirst(): boolean {
        return Enum4Type.First === this._dataType;
    }
    isSecond(): boolean {
        return Enum4Type.Second === this._dataType;
    }
    isThird(): boolean {
        return Enum4Type.Third === this._dataType;
    }
    isFourth(): boolean {
        return Enum4Type.Fourth === this._dataType;
    }
    unwrapFirstOr(alternate: NonNullable<T1>): NonNullable<T1> {
        if (this.isFirst()) {
            return this._data as any;
        } else {
            return alternate;
        }
    }
    unwrapFirstOrElse(fn: () => NonNullable<T1>): NonNullable<T1> {
        if (this.isFirst()) {
            return this._data as any;
        } else {
            return fn();
        }
    }
    unwrapSecondOr(alternate: NonNullable<T2>): NonNullable<T2> {
        if (this.isSecond()) {
            return this._data as any;
        } else {
            return alternate;
        }
    }
    unwrapSecondOrElse(fn: () => NonNullable<T2>): NonNullable<T2> {
        if (this.isSecond()) {
            return this._data as any;
        } else {
            return fn();
        }
    }
    unwrapThirdOr(alternate: NonNullable<T3>): NonNullable<T3> {
        if (this.isThird()) {
            return this._data as any;
        } else {
            return alternate;
        }
    }
    unwrapThirdOrElse(fn: () => NonNullable<T3>): NonNullable<T3> {
        if (this.isThird()) {
            return this._data as any;
        } else {
            return fn();
        }
    }
    unwrapFourthOr(alternate: NonNullable<T4>): NonNullable<T4> {
        if (this.isFourth()) {
            return this._data as any;
        } else {
            return alternate;
        }
    }
    unwrapFourthOrElse(fn: () => NonNullable<T4>): NonNullable<T4> {
        if (this.isFourth()) {
            return this._data as any;
        } else {
            return fn();
        }
    }
    match(firstFn?: Handler<T1>, secondFn?: Handler<T2>, thirdFn?: Handler<T3>, fourthFn?: Handler<T4>) {
        if (this.isFirst() && firstFn) {
            firstFn(this._data as any);
        } else if (this.isSecond() && secondFn) {
            secondFn(this._data as any);
        } else if (this.isThird() && thirdFn) {
            thirdFn(this._data as any);
        } else if (this.isFourth() && fourthFn) {
            fourthFn(this._data as any);
        }
    }
    matchFirst(fn: Handler<T1>) {
        if (this.isFirst()) {
            fn(this._data as any);
        }
    }
    matchSecond(fn: Handler<T2>) {
        if (this.isSecond()) {
            fn(this._data as any);
        }
    }
    matchThird(fn: Handler<T3>) {
        if (this.isThird()) {
            fn(this._data as any);
        }
    }
    matchFourth(fn: Handler<T4>) {
        if (this.isFourth()) {
            fn(this._data as any);
        }
    }
    async matchAsync(firstFn?: AsyncHandler<T1>, secondFn?: AsyncHandler<T2>, thirdFn?: AsyncHandler<T3>, fourthFn?: AsyncHandler<T4>) {
        if (this.isFirst() && firstFn) {
            await Promise.resolve(firstFn(this._data as any));
        } else if (this.isSecond() && secondFn) {
            await Promise.resolve(secondFn(this._data as any));
        } else if (this.isThird() && thirdFn) {
            await Promise.resolve(thirdFn(this._data as any));
        } else if (this.isFourth() && fourthFn) {
            await Promise.resolve(fourthFn(this._data as any));
        }
    }
    async matchFirstAsync(fn: AsyncHandler<T1>) {
        if (this.isFirst()) {
            await Promise.resolve(fn(this._data as any));
        }
    }
    async matchSecondAsync(fn: AsyncHandler<T2>) {
        if (this.isSecond()) {
            await Promise.resolve(fn(this._data as any));
        }
    }
    async matchThirdAsync(fn: AsyncHandler<T3>) {
        if (this.isThird()) {
            await Promise.resolve(fn(this._data as any));
        }
    }
    async matchFourthAsync(fn: AsyncHandler<T4>) {
        if (this.isFourth()) {
            await Promise.resolve(fn(this._data as any));
        }
    }
    mapFirst<O>(mapFn: Mapper<T1, O>): Enum4<O, T2, T3, T4> {
        if (this.isFirst()) {
            return Enum4.First(mapFn(this._data as any));
        } else if (this.isSecond()) {
            return Enum4.Second(this._data as any);
        } else if (this.isThird()) {
            return Enum4.Third(this._data as any);
        } else {
            return Enum4.Fourth(this._data as any);
        }
    }
    mapSecond<O>(mapFn: Mapper<T2, O>): Enum4<T1, O, T3, T4> {
        if (this.isSecond()) {
            return Enum4.Second(mapFn(this._data as any));
        } else if (this.isFirst()) {
            return Enum4.First(this._data as any);
        } else if (this.isThird()) {
            return Enum4.Third(this._data as any);
        } else {
            return Enum4.Fourth(this._data as any);
        }
    }
    mapThird<O>(mapFn: Mapper<T3, O>): Enum4<T1, T2, O, T4> {
        if (this.isThird()) {
            return Enum4.Third(mapFn(this._data as any));
        } else if (this.isFirst()) {
            return Enum4.First(this._data as any);
        } else if (this.isSecond()) {
            return Enum4.Second(this._data as any);
        } else {
            return Enum4.Fourth(this._data as any);
        }
    }
    mapFourth<O>(mapFn: Mapper<T4, O>): Enum4<T1, T2, T3, O> {
        if (this.isFourth()) {
            return Enum4.Fourth(mapFn(this._data as any));
        } else if (this.isFirst()) {
            return Enum4.First(this._data as any);
        } else if (this.isSecond()) {
            return Enum4.Second(this._data as any);
        } else {
            return Enum4.Third(this._data as any);
        }
    }
    mapAll<O>(firstFn: Mapper<T1, O>, secondFn: Mapper<T2, O>, thirdFn: Mapper<T3, O>, fourthFn: Mapper<T4, O>): O {
        if (this.isFirst()) {
            return firstFn(this._data as any);
        } else if (this.isSecond()) {
            return secondFn(this._data as any);
        } else if (this.isThird()) {
            return thirdFn(this._data as any);
        } else {
            return fourthFn(this._data as any);
        }
    }
    async mapFirstAsync<O>(mapFn: AsyncMapper<T1, O>): Promise<Enum4<O, T2, T3, T4>> {
        if (this.isFirst()) {
            return Enum4.First(await Promise.resolve(mapFn(this._data as any)));
        } else if (this.isSecond()) {
            return Enum4.Second(this._data as any);
        } else if (this.isThird()) {
            return Enum4.Third(this._data as any);
        } else {
            return Enum4.Fourth(this._data as any);
        }
    }
    async mapSecondAsync<O>(mapFn: AsyncMapper<T2, O>): Promise<Enum4<T1, O, T3, T4>> {
        if (this.isSecond()) {
            return Enum4.Second(await Promise.resolve(mapFn(this._data as any)));
        } else if (this.isFirst()) {
            return Enum4.First(this._data as any);
        } else if (this.isThird()) {
            return Enum4.Third(this._data as any);
        } else {
            return Enum4.Fourth(this._data as any);
        }
    }
    async mapThirdAsync<O>(mapFn: AsyncMapper<T3, O>): Promise<Enum4<T1, T2, O, T4>> {
        if (this.isThird()) {
            return Enum4.Third(await Promise.resolve(mapFn(this._data as any)));
        } else if (this.isFirst()) {
            return Enum4.First(this._data as any);
        } else if (this.isSecond()) {
            return Enum4.Second(this._data as any);
        } else {
            return Enum4.Fourth(this._data as any);
        }
    }
    async mapFourthAsync<O>(mapFn: AsyncMapper<T4, O>): Promise<Enum4<T1, T2, T3, O>> {
        if (this.isFourth()) {
            return Enum4.Fourth(await Promise.resolve(mapFn(this._data as any)));
        } else if (this.isFirst()) {
            return Enum4.First(this._data as any);
        } else if (this.isSecond()) {
            return Enum4.Second(this._data as any);
        } else {
            return Enum4.Third(this._data as any);
        }
    }
    async mapAllAsync<O>(firstFn: AsyncMapper<T1, O>, secondFn: AsyncMapper<T2, O>, thirdFn: AsyncMapper<T3, O>, fourthFn: AsyncMapper<T4, O>): Promise<O> {
        if (this.isFirst()) {
            return firstFn(this._data as any);
        } else if (this.isSecond()) {
            return secondFn(this._data as any);
        } else if (this.isThird()) {
            return thirdFn(this._data as any);
        } else {
            return fourthFn(this._data as any);
        }
    }
}

export interface ApiResp<T> {
    totalCount: number;
    errCode: number;
    message: string;
    result: T;
}

export interface ApiError {
    errCode: number;
    message: string;
}

export function responseToResult<T>(resp: ApiResp<T>): Result<T, ApiError> {
    if (resp.errCode === 0) {
        return Result.Ok(resp.result);
    } else {
        return Result.Err(resp);
    }
}

export async function resultToPromise<T, E>(result: Result<T, E>): Promise<T> {
    return new Promise(function (resolve, reject) {
        result.match(resolve, reject);
    });
}
