import { Option, Result, ApiError } from "../common";
import { ReqType, ajax } from "../utils";
import { AesKey256 } from "../crypto";
import { EncryptedMeta } from "../api/portal/queryEncryptedMeta";
export { ReqType } from "../utils";

export interface RespByType<O> {
    string: string;
    uint8array: Uint8Array;
    file: File;
    json: O;
}

export type RespType<O> = keyof RespByType<O>;

export enum Method {
    Get = "GET",
    Post = "POST",
    Put = "PUT",
    Delete = "DELETE",
}

export interface RequestParams<Req, R extends RespType<any>> {
    method: Method;
    url: string;
    data?: ReqType<Req>;
    headers?: {
        [key: string]: string | string[];
    };
    respType: R;
    onProgress?: (evt: ProgressEvent<EventTarget>) => void;
    onUploadProgress?: (evt: ProgressEvent<EventTarget>) => void;
}

export interface HttpRequestor {
    <Req, Resp, R extends RespType<Resp>>(params: RequestParams<Req, R>): Promise<Result<RespByType<Resp>[R], string>>;
}

export const defaultHttpRequestor: HttpRequestor = async function <Req, Resp, R extends RespType<Resp>>(params: RequestParams<Req, R>): Promise<Result<RespByType<Resp>[R], string>> {
    let result = await ajax<Req, Resp, R>(params.url, params.data, {
        method: params.method,
        headers: params.headers,
        respType: params.respType,
        onProgress: params.onProgress,
        onUploadProgress: params.onUploadProgress
    });
    return result.mapErr(err => err.msg);
};

export interface Adapter<AuthParams, KeyChain> {
    queryKeyChain(authParams: AuthParams): Promise<Result<KeyChain | undefined, ApiError>>;
    lookupMetaKey(keyChain: KeyChain): {
        metaKeyId: number;
        metaKey: AesKey256;
    } | undefined;
    findMetaKeyById(keyChain: KeyChain, metaKeyId: number): Option<AesKey256>;
    queryEncryptedMeta(authParams: AuthParams): Promise<Result<EncryptedMeta[], ApiError>>;
    httpRequest?: HttpRequestor;
}