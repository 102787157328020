import { Option, Result } from "./common";

export type FetchHandler<Data, Err> = () => Promise<Result<Data, Err>>;

export interface CacheMgr<Data, Err> {
    //获取一个新鲜的数据
    getFreshData(): Promise<Result<Data, Err>>;
    //清除已缓存的数据
    clearData();
}

interface CacheData<Data> {
    data: Data;
    time: Date;
}

function cacheExpired<Data>(cache: CacheData<Data>, timeout: number): boolean {
    return new Date().getTime() > cache.time.getTime() + timeout;
}

/**
 * 创建一个缓存管理器
 * @param fetchHandler 获取数据的异步函数
 * @param timeout 数据过期时间，单位毫秒
 * @param immediate 是否立即执行一次数据获取，默认false不执行，如果为true，则在执行的时候就调用一次fetchHandler函数获取数据
 * @returns 
 */
export function buildCacheMgr<Data, Err>(fetchHandler: FetchHandler<Data, Err>, timeout: number, immediate?: boolean): CacheMgr<Data, Err> {
    let cache: Option<CacheData<Data>> = Option.None();
    let fetchTask: Option<Promise<Result<Data, Err>>> = Option.None();
    let fetchNewData = function () {
        let task = Promise.resolve(fetchHandler()).then((result) => {
            result.match((data) => {
                cache.replace({
                    data: data,
                    time: new Date()
                });
                return data;
            });
            fetchTask.take();
            return result;
        }, (err) => {
            fetchTask.take();
            throw err;
        });
        fetchTask.replace(task);
        return task;
    };
    if (immediate) {
        fetchNewData();
    }
    return {
        getFreshData(): Promise<Result<Data, Err>> {
            return new Promise((resolve, reject) => {
                let fetchData = () => {
                    let promise;
                    fetchTask.match((task) => {
                        //已经有查询任务在执行，直接添加回调就可以了
                        promise = task;
                    }, () => {
                        //当前没有查询任务，创建一个查询任务
                        promise = fetchNewData();
                    });
                    promise.then(resolve, reject);
                };
                cache.match((cacheData) => {
                    //有缓存
                    if (cacheExpired(cacheData, timeout)) {
                        //缓存已经过期，重新拉取一次
                        fetchData();
                    } else {
                        //缓存未过期
                        resolve(Result.Ok(cacheData.data));
                    }
                }, fetchData);
            });
        },
        clearData() {
            cache.take();
        }
    };
}
