
export enum OwnerType {
    VNO = 0,
    Brand = 1,
}

export enum BusinessType {
    Common = 0,
    HalfSafeEpg = 1, //非纯zero trust的epg meta key
    Epg = 2,
    Stream = 3
}
