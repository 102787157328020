
export type Task = () => Promise<void>;

function buildWorker(nextTask: () => Task | undefined): Task {
    return async function () {
        let task = nextTask();
        while (task) {
            await task();
            task = nextTask();
        }
    };
}

/**
 * 受限地执行并发任务
 * @param tasks 任务集合
 * @param count 任务执行最大并发数量
 * @returns 
 */
export async function executeTasksLimitedly(tasks: Task[], count: number) {
    let nextTask = () => {
        return tasks.pop();
    };
    let workers: Task[] = [];
    for (let i = 0; i < count; i++) {
        workers.push(buildWorker(nextTask));
    }
    return Promise.all(workers.map((worker) => worker()));
}
