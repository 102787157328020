
enum Field {
    FileFlag = "GSFC", //文件标识
    FileSize = "GSFS", //文件大小
    KeyId = "KYID", //metaKeyId
    Data = "EDAT" //文件内容
}

const FIELDS = [Field.FileFlag, Field.FileSize, Field.KeyId, Field.Data];

// function bitsToBytes(bits: boolean[]): number[] {
//   let bytes: number[] = [];
//   let byte: number = 0;
//   let count = 0;
//   while (true) {
//     let bit = bits.pop();
//     if ("boolean" == typeof bit) {
//       byte = byte * 2 + (bit ? 1 : 0);
//       count += 1;
//       if (8 == count) {
//         bytes.unshift(byte);
//         byte = 0;
//         count = 0;
//       }
//     } else {
//       break;
//     }
//   }
//   if (0 < count) {
//     bytes.unshift(byte);
//   }
//   return bytes;
// }

// function encodeNumber(num: number): number[] {
//   const bits = Math.round(num).toString(2).split(".")[0];
//   let bytes = bitsToBytes(Array.prototype.map.call(bits, (bit) => {
//     return "1" == bit;
//   }) as any);
//   while (4 > bytes.length) {
//     bytes.unshift(0);
//   }
//   return bytes;
// }

const BYTE_SIZE = 256;

function encodeNumber(num: number, precision: number): number[] {
    let bytes: number[] = [];
    for (let i = 0; i < precision; i++) {
        bytes.unshift(num % BYTE_SIZE);
        num = Math.floor(num / BYTE_SIZE);
    }
    return bytes;
}

function appendBytes(bytes: number[], newBytes: any) {
    Array.prototype.forEach.call(newBytes, (num) => {
        bytes.push(num);
    });
}

function appendSegment(data: number[], field: string, segPrecision: number, segData: Uint8Array) {
    const encoder = new TextEncoder();
    let fieldBytes = encoder.encode(field);
    let size = fieldBytes.length + segPrecision + segData.length;
    let sizeBytes = encodeNumber(size, segPrecision);
    appendBytes(data, fieldBytes);
    appendBytes(data, sizeBytes);
    appendBytes(data, segData);
    return data;
}

/**
 * 对二进制数据进行编码
 * @param data 二进制数据（metaKeyId为0时需要传原始数据而不是加密后的数据）
 * @param metaKeyId 值为0时表示data数据未加密
 * @returns 
 */
export function encodeBlob(data: Uint8Array, metaKeyId: number): Uint8Array {
    const encoder = new TextEncoder();
    let bytes: number[] = [];
    appendSegment(bytes, Field.FileFlag, 4, encoder.encode("0000"));
    appendSegment(bytes, Field.FileSize, 4, new Uint8Array(encodeNumber(data.length, 16)));
    appendSegment(bytes, Field.KeyId, 4, new Uint8Array(encodeNumber(metaKeyId, 16)));
    appendSegment(bytes, Field.Data, 16, data);
    return new Uint8Array(bytes);
}


function startsWith(data: Uint8Array, tag: Uint8Array): boolean {
    let len = tag.length;
    if (0 == len || data.length < len) {
        return false;
    }
    for (let i = 0; i < len; i++) {
        if (data[i] != tag[i]) {
            return false;
        }
    }
    return true;
}

function tryRead(data: Uint8Array, len: number): {
    bytes: Uint8Array;
    rest: Uint8Array;
} {
    if (data.length < len) {
        throw "data not long enough.";
    }
    return {
        bytes: data.slice(0, len),
        rest: data.slice(len)
    };
}

function decodeNumber(bytes: any): number {
    let num = 0;
    Array.prototype.forEach.call(bytes, (byte) => {
        num = num * BYTE_SIZE + byte;
    });
    return num;
}

export interface BlobData {
    fileFlag: Uint8Array; //文件标识，4字节"0000"
    fileSize: number; //文件大小
    metaKeyId: number; //metaKeyId
    data: Uint8Array; //数据内容
}

/**
 * 对二进制数据进行解码
 * @param data 已编码的数据，目前不支持流式解码，data必须是完整的数据
 * @returns 
 */
export function tryDecodeBlob(data: Uint8Array): BlobData {
    const encoder = new TextEncoder();
    let fields = FIELDS.map((field) => {
        return {
            field: field,
            tag: encoder.encode(field)
        };
    });
    let result = {
        fields: [] as {
            field: Field;
            content: Uint8Array;
        }[],
        data: undefined as undefined | Uint8Array,
    };
    while (data.length) {
        let found = false;
        for (let item of fields) {
            if (startsWith(data, item.tag)) {
                if (Field.Data == item.field) {
                    //数据段，size占16字节
                    let { bytes, rest } = tryRead(data.slice(item.tag.length), 16);
                    result.data = rest;
                } else {
                    //其它段，size占4字节
                    let sizeInfo = tryRead(data.slice(item.tag.length), 4);
                    let contentLen = decodeNumber(sizeInfo.bytes) - item.tag.length - 4;
                    let contentInfo = tryRead(sizeInfo.rest, contentLen);
                    result.fields.push({
                        field: item.field,
                        content: contentInfo.bytes
                    });
                    data = contentInfo.rest;
                }
                found = true;
                break;
            }
        }
        if (found) {
            if ("undefined" != typeof result.data) {
                //数据段是最后一段，如果已经获取到，则不再解析
                break;
            }
        } else {
            throw "Invalid format of data.";
        }
    }
    let blobOpt: {
        fileFlag?: Uint8Array;
        fileSize?: Uint8Array;
        metaKeyId?: Uint8Array;
        data?: Uint8Array;
    } = {
        fileFlag: undefined,
        fileSize: undefined,
        metaKeyId: undefined,
        data: undefined,
    };
    for (let item of result.fields) {
        if (Field.FileFlag == item.field) {
            blobOpt.fileFlag = item.content;
        } else if (Field.FileSize == item.field) {
            blobOpt.fileSize = item.content;
        } else if (Field.KeyId == item.field) {
            blobOpt.metaKeyId = item.content;
        }
    }
    blobOpt.data = result.data;
    if ("undefined" == typeof blobOpt.fileFlag
        || "undefined" == typeof blobOpt.fileSize
        || "undefined" == typeof blobOpt.metaKeyId
        || "undefined" == typeof blobOpt.data) {
        throw "Data is not complete.";
    }
    return {
        fileFlag: blobOpt.fileFlag,
        fileSize: decodeNumber(blobOpt.fileSize),
        metaKeyId: decodeNumber(blobOpt.metaKeyId),
        data: blobOpt.data,
    };
}