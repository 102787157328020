import { Bytes, AesKey256, RsaPubKey2048, RsaPriKey2048, isValidRsaKeyPair } from "./crypto";
import {
    tryDecryptMasterKey,
    tryDecryptRsaPriKeyByMasterKey,
    tryDecryptShareKeyByMasterKey,
    tryDecryptMetaKeyByMasterKey,
    tryDecryptShareKeyByRsaPriKey,
    tryDecryptMetaKeyByShareKey,
} from "./base";
import { OwnerType, BusinessType } from "./api/models";
import { EnterpriseKeyChain as CipherEnterpriseKeyChain } from "./api/portal/getEnterpriseKeyChain";
import { ShareKeyInfo as ShareKeyDetail } from "./api/portal/getBrandShareKey";

interface MetaKey {
    id: number;
    metaKey: AesKey256;
    businessType: BusinessType;
}

interface EnterpriseKeyInfo {
    userRandomValue: string;
    rsaPrivateKey: RsaPriKey2048;
    rsaPublicKey: RsaPubKey2048;
    masterKey: AesKey256;
    metaKeys: MetaKey[];
}

interface SharedKeyInfo {
    ownerId: number;
    metaKeyId: number;
    shareKey: AesKey256;
    metaKey: AesKey256;
}

interface ShareKeyInfo {
    id: number;
    ownerId: number;
    ownerType: OwnerType;
    brandId?: number;
    shareKey: AesKey256;
}

export interface EnterpriseKeyChain {
    enterpriseKeyInfo: EnterpriseKeyInfo;
    shareKeyInfo: ShareKeyInfo[];
    sharedKeyInfo: SharedKeyInfo[];
}

export interface MerchantShareKeyChain {
    vnoId: number;
    shareKey: AesKey256;
    metaKeys: MetaKey[];
}

export interface BrandShareKeyChain {
    brandId: number;
    shareKey: AesKey256;
    metaKeys: MetaKey[];
}

/**
 * 解密企业秘钥链
 * @returns 
 */
export function tryDecryptEnterpriseKeyChain(cipherEnterpriseKeyChain: CipherEnterpriseKeyChain, enterpriseEncryptKey: AesKey256): EnterpriseKeyChain | undefined {
    let encryptedMasterKey = Bytes.tryFromBase64(cipherEnterpriseKeyChain.enterpriseKeyInfo.encryptedMasterKey);
    let masterKey;
    try {
        masterKey = tryDecryptMasterKey(encryptedMasterKey, enterpriseEncryptKey);
    } catch (ex) {
        //解密失败，enterpriseEncryptKey对应的密码不正确，返回null
        console.error(ex);
        return undefined;
    }
    let rsaPrivateKey = tryDecryptRsaPriKeyByMasterKey(Bytes.tryFromBase64(cipherEnterpriseKeyChain.enterpriseKeyInfo.encryptedRsaPrivateKey), masterKey);
    let rsaPublicKey = RsaPubKey2048.tryFromString(cipherEnterpriseKeyChain.enterpriseKeyInfo.rsaPublicKey);
    let ok = isValidRsaKeyPair(rsaPublicKey, rsaPrivateKey);
    if (!ok) {
        return undefined;
    }
    return {
        enterpriseKeyInfo: {
            userRandomValue: cipherEnterpriseKeyChain.enterpriseKeyInfo.userRandomValue,
            rsaPrivateKey: rsaPrivateKey,
            rsaPublicKey: rsaPublicKey,
            masterKey: masterKey,
            metaKeys: cipherEnterpriseKeyChain.enterpriseKeyInfo.metaKeys.map((metaKey) => {
                return {
                    id: metaKey.id,
                    metaKey: tryDecryptMetaKeyByMasterKey(Bytes.tryFromBase64(metaKey.encryptedMetaKey), masterKey),
                    businessType: metaKey.businessType
                };
            })
        },
        shareKeyInfo: cipherEnterpriseKeyChain.shareKeyInfo.map((shareKeyInfo) => {
            let shareKey = tryDecryptShareKeyByMasterKey(Bytes.tryFromBase64(shareKeyInfo.encryptedKey), masterKey);
            return {
                id: shareKeyInfo.id,
                ownerId: shareKeyInfo.ownerId,
                ownerType: shareKeyInfo.ownerType,
                brandId: shareKeyInfo.brandId,
                shareKey: shareKey
            };
        }),
        sharedKeyInfo: cipherEnterpriseKeyChain.sharedKeyInfo.map((shareKeyInfo) => {
            let shareKey = tryDecryptShareKeyByRsaPriKey(Bytes.tryFromBase64(shareKeyInfo.encryptedShareKey), rsaPrivateKey)!;
            let metaKey = tryDecryptMetaKeyByShareKey(Bytes.tryFromBase64(shareKeyInfo.encryptedMetaKey), shareKey);
            return {
                ownerId: shareKeyInfo.ownerId,
                metaKeyId: shareKeyInfo.metaKeyId,
                shareKey: shareKey,
                metaKey: metaKey,
            };
        })
    };
}

/**
 * 根据VNO分享码解密metaKey
 * @returns 
 */
export function tryDecryptMerchantKeyChainByShareKey(shareKeyInfo: ShareKeyDetail, shareKey: AesKey256, vnoId: number): MerchantShareKeyChain {
    return {
        vnoId: vnoId,
        shareKey: shareKey,
        metaKeys: shareKeyInfo.metaKeys.map((metaKey) => {
            return {
                id: metaKey.id,
                metaKey: tryDecryptMetaKeyByShareKey(Bytes.tryFromBase64(metaKey.encryptedMetaKey), shareKey),
                businessType: metaKey.businessType
            };
        })
    };
}

/**
 * 根据品牌公共分享码解密metaKey
 * @returns 
 */
export function tryDecryptBrandKeyChainByShareKey(shareKeyInfo: ShareKeyDetail, pubShareKey: AesKey256, brandId: number): BrandShareKeyChain {
    return {
        brandId: brandId,
        shareKey: pubShareKey,
        metaKeys: shareKeyInfo.metaKeys.map((metaKey) => {
            return {
                id: metaKey.id,
                metaKey: tryDecryptMetaKeyByShareKey(Bytes.tryFromBase64(metaKey.encryptedMetaKey), pubShareKey),
                businessType: metaKey.businessType
            };
        })
    };
}
