import { Option, Result, Enum4 } from "./common";

export type FormData = {
  [key: string]: string;
};

export class ReqType<T> {
  private _data: Enum4<T, FormData, string, any>;
  private constructor(data: Enum4<T, FormData, string, any>) {
    this._data = data;
  }
  static Json<T>(json: T): ReqType<T> {
    return new ReqType<T>(Enum4.First(json));
  }
  static Form(form: FormData): ReqType<void> {
    return new ReqType(Enum4.Second(form));
  }
  static Text(text: string): ReqType<void> {
    return new ReqType(Enum4.Third(text));
  }
  static Native(native: any): ReqType<void> {
    return new ReqType(Enum4.Fourth(native));
  }
  serialize(): {
    content: any;
    contentType?: string;
  } {
    return this._data.mapAll((json) => {
      return {
        contentType: 'application/json; charset=UTF-8',
        content: JSON.stringify(json)
      };
    }, (form) => {
      return {
        contentType: 'application/x-www-form-urlencoded; charset=UTF-8',
        content: Object.keys(form)
          .filter((key) => null != form[key])
          .map(function (key) {
            return key + '=' + encodeURIComponent(form[key]);
          })
          .join('&')
      };
    }, (text) => {
      return {
        contentType: 'text/plain; charset=UTF-8',
        content: text
      };
    }, (native) => {
      return {
        content: native
      };
    });
  }
}

export interface RespByType<O> {
  string: string;
  uint8array: Uint8Array;
  file: File;
  json: O;
}

export type RespType<O> = keyof RespByType<O>;

export enum Method {
  Get = "GET",
  Post = "POST",
  Put = "PUT",
  Delete = "DELETE",
}

export interface OptionalConfig<R extends RespType<any>> {
  method?: Method;
  respType?: R;
  withCredentials?: boolean;
  headers?: {
    [header: string]: string | string[];
  };
  onProgress?: (evt: ProgressEvent<EventTarget>) => void;
  onUploadProgress?: (evt: ProgressEvent<EventTarget>) => void;
}

function getFileNameFromHeader(contentDisposition: string | null): string | undefined {
  if (contentDisposition) {
    let name = contentDisposition.split("filename=").pop();
    if (name) {
      return name.replace(/^\"/, "").replace(/\"$/, "");
    }
  }
  return undefined;
}

function getFileNameFromUrl(url: string, allowNoSuffix: boolean): string | undefined {
  let uri = decodeURIComponent(url).split("?")[0];
  let content = uri.replace(/:\/\//i, "");
  let pos = content.lastIndexOf("/");
  if (0 <= pos) {
    let fileName = content.substring(pos + 1);
    if (!fileName) {
      return undefined;
    } else {
      if (!allowNoSuffix && 0 > fileName.indexOf(".")) {
        return undefined;
      } else {
        return fileName;
      }
    }
  } else {
    return undefined;
  }
}

/**
 * ajax请求方法（只发post请求）
 * @param {String} url 请求接口
 * @param {ReqType<Req>} req 数据对象
 * @param {OptionalConfig<R>} opt 可选项
 *                     opt.method请求方法，默认为POST
 *                     opt.withCredentials为true的时候支持跨域请求
 *                     opt.headers为额外的请求头
 *                     opt.onUploadProgress上传进度钩子函数，参数类型为ProgressEvent
 * @return {Promise<Result<RespByType<Resp>[R], {code: number;msg: string;xhr?: XMLHttpRequest;}>>}
 */
export function ajax<Req, Resp, R extends RespType<Resp>>(
  url: string,
  req?: ReqType<Req>,
  originalOpt?: OptionalConfig<R>
): Promise<
  Result<
    RespByType<Resp>[R],
    {
      code: number;
      msg: string;
      xhr?: XMLHttpRequest;
    }
  >
> {
  return new Promise(function (resolve, reject) {
    let xhr: XMLHttpRequest;
    if (window.XMLHttpRequest) {
      xhr = new window.XMLHttpRequest();
    } else if (window.ActiveXObject) {
      xhr = new window.ActiveXObject('Microsoft.XMLHTTP');
    } else {
      resolve(
        Result.Err({
          code: 1,
          msg: 'ajax not supported',
        })
      );
      return;
    }
    let opt = originalOpt || {};
    let respType: R = opt.respType || 'json' as R;
    let responseType: XMLHttpRequestResponseType = {
      string: "text",
      uint8array: "arraybuffer",
      file: "blob",
      json: "json"
    }[respType] as XMLHttpRequestResponseType;
    xhr.responseType = responseType;
    xhr.onreadystatechange = function () {
      if (4 == xhr.readyState) {
        if (200 <= xhr.status && 300 > xhr.status) {
          if ("uint8array" == respType) {
            resolve(Result.Ok(new Uint8Array(xhr.response) as any));
          } else if ("file" == respType) {
            let name = getFileNameFromHeader(xhr.getResponseHeader("content-disposition"))
              || getFileNameFromUrl(url, false);
            resolve(Result.Ok(new File([xhr.response], name!) as any));
          } else {
            resolve(Result.Ok(xhr.response));
          }
        } else if (0 == xhr.status) {
          resolve(
            Result.Err({
              code: 2,
              msg: 'network error',
              xhr: xhr,
            })
          );
        } else {
          resolve(
            Result.Err({
              code: 3,
              msg: 'server error',
              xhr: xhr,
            })
          );
        }
      }
    };
    if (opt.onProgress) {
      const onProgress = opt.onProgress;
      xhr.addEventListener("progress", onProgress);
    }
    if (opt.onUploadProgress) {
      const onUploadProgress = opt.onUploadProgress;
      xhr.upload.onprogress = onUploadProgress;
    }
    const method = opt.method || Method.Post;
    if (Method.Get === method && req) {
      let queryString = req.serialize().content;
      if (queryString) {
        if (url.includes('?')) {
          url = url + '&' + queryString;
        } else {
          url = url + '?' + queryString;
        }
      }
    }
    xhr.open(method, url, true);
    if (opt.headers) {
      const headers = opt.headers;
      Object.keys(headers).forEach((header) => {
        const vals = headers[header];
        if (undefined === vals || null === vals) {
          return;
        }
        if ('string' === typeof vals || !vals.forEach) {
          xhr.setRequestHeader(header, '' + vals);
        } else {
          vals.forEach((val) => {
            xhr.setRequestHeader(header, val);
          });
        }
      });
    }
    let body: any = null;
    if (req && (Method.Post === method || Method.Put === method)) {
      let data = req.serialize();
      if (data.contentType) {
        xhr.setRequestHeader('Content-Type', data.contentType);
      }
      body = data.content;
    }
    if (opt.withCredentials) {
      xhr.withCredentials = true;
    }
    xhr.send(body as any);
  });
}
